<template>
  <v-main>
    <Header
      :title="txt.pageTitle"
      :subtitle="txt.pageSubtitle"
      :itemsBreadCrumb="itemsBreadCrumb"
      :hasAddButton="false"
      :hasConfigButton="true"
      @clickConfig="redirectTo"
      :hasUpdateInfo="false"
    ></Header>
    <div><TabsRubrica /></div>
  </v-main>
</template>

<script>
export default {
  name: 'RubricasNew',
  data() {
    return {
      search: '',
      filters: {},
      itemsBreadCrumb: [
        {
          title: 'Home',
          disabled: false,
          href: 'home',
        },
        {
          title: 'e-Social',
          disabled: true,
        },
        {
          title: 'Rubricas',
          disabled: true,
          active: true,
        },
      ],
      txt: {
        pageTitle: 'Rubricas',
        pageSubtitle: 'Analise rubricas e gerencie as aplicações de recomendações',
      },
    };
  },
  components: {
    Header: () => import('../commons/Header.vue'),
    TabsRubrica: () => import('./components/TabsRubrica.vue'),
  },
  methods: {
    redirectTo() {
      this.$router.push('/cadastros-gerenciais/e-social/tables');
    },
  },
};
</script>

<style lang="scss" scoped></style>
