var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c(
        "div",
        { staticClass: "d-flex justify-space-between" },
        [
          _c("v-col", [
            _c(
              "h2",
              {
                staticClass: "mt-8 mx-n3 titleColor--text",
                staticStyle: { "font-size": "32px !important" },
              },
              [_vm._v("Rubricas")]
            ),
            _c("p", { staticClass: "mx-n3", attrs: { id: "subtitle" } }, [
              _vm._v("Gerenciamento das rubricas e seus parâmetros"),
            ]),
          ]),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mt-16",
                              attrs: {
                                id: "buttonAddRubrica",
                                color: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.openNewRubricaModal()
                                },
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-plus"),
                          ]),
                          _vm._v(" Adicionar rubrica "),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("Adicionar rubrica")])]
          ),
        ],
        1
      ),
      _c(
        "v-card",
        {
          staticClass: "d-flex flex-column pa-4",
          attrs: { id: "cardDash", elevation: "0" },
        },
        [
          _c("FiltroRubrica"),
          _c(
            "div",
            { staticClass: "d-flex justify-space-around mx-8" },
            [
              _c(
                "v-col",
                {
                  staticClass: "mr-4",
                  staticStyle: { padding: "0px !important" },
                  attrs: { cols: "8" },
                },
                [_c("TotalForSituation")],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "ml-4",
                  staticStyle: { padding: "0px !important" },
                  attrs: { cols: "4" },
                },
                [_c("SituationOverview")],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "ma-4" }, [_c("Carousel")], 1),
          _c("table-rubricas"),
          _c("modal-rubrica-nova", {
            ref: "modalRubricaNova",
            on: { added: _vm.refresh },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }