<template>
  <v-main
    ><div class="d-flex justify-space-between">
      <v-col>
        <h2 class="mt-8 mx-n3 titleColor--text" style="font-size: 32px !important">Rubricas</h2>
        <p class="mx-n3" id="subtitle">Gerenciamento das rubricas e seus parâmetros</p></v-col
      >
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            id="buttonAddRubrica"
            class="mt-16"
            @click="openNewRubricaModal()"
            v-bind="attrs"
            v-on="on"
            color="primary"
          >
            <v-icon left>mdi-plus</v-icon>
            Adicionar rubrica
          </v-btn>
        </template>
        <span>Adicionar rubrica</span>
      </v-tooltip>
    </div>
    <v-card class="d-flex flex-column pa-4" id="cardDash" elevation="0">
      <FiltroRubrica />
      <div class="d-flex justify-space-around mx-8">
        <v-col cols="8" style="padding: 0px !important" class="mr-4"><TotalForSituation /></v-col>
        <v-col cols="4" style="padding: 0px !important" class="ml-4"><SituationOverview /></v-col>
      </div>
      <div class="ma-4">
        <Carousel />
      </div>
      <table-rubricas />
      <modal-rubrica-nova ref="modalRubricaNova" @added="refresh" />
    </v-card>
  </v-main>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'RubricasNew',
  components: {
    ModalRubricaNova: () => import('./components/ModalRubricNova.vue'),

    ModalApproveRubrica: () => import('./components/ModalApproveRubrica.vue'),
    Carousel: () => import('./components/Carousel.vue'),
    FiltroRubrica: () => import('./components/FiltroRubricas.vue'),
    SituationOverview: () => import('./components/SituationOverview.vue'),
    TotalForSituation: () => import('./components/TotalForSituation.vue'),
    TableRubricas: () => import('./components/TableRubricas.vue'),
  },
  data() {
    return {
      filter: {},

      rubricOrigin: '',
      suggestion: '',

      headers: [
        { align: 'left', text: 'Rubricas', value: 'dscRubrDadosrubrica' },
        { align: 'left', text: 'Empresa', value: 'company', sortable: false, width: '22%' },
        { align: 'left', text: 'Operação', value: 'operacao', sortable: false },
        { align: 'left', text: 'Validade', value: 'validity', sortable: false, width: '10%' },
        { align: 'left', text: 'Última alteração', value: 'dataRegistro', width: '11%' },

        { align: 'center', text: 'Situação', value: 'status' },
      ],

      filterStatusItems: [],
    };
  },

  computed: {
    ...mapGetters({
      userRoles: 'user/roles',
      params: 'esocial/esocialMain/getParams',
      counters: 'esocial/esocialMain/getTotalForSituation',
      dscRubrDadosrubrica: 'esocial/esocialMain/dscRubrDadosrubrica',
      iniValidIderubrica: 'esocial/esocialMain/iniValidIderubrica',
      fimValidIderubrica: 'esocial/esocialMain/fimValidIderubrica',
      nrInscIdeempregador: 'esocial/esocialMain/nrInscIdeempregador',
      loadingData: 'esocial/esocialMain/getLoading',
      totalRubricas: 'esocial/esocialMain/getTotalRubricas',
      rubricas: 'esocial/esocialMain/getRubricas',
      paginationFilter: 'esocial/esocialMain/pagination',
      routerToFrom: 'routerModule/route',
    }),
  },

  watch: {
    options: {
      handler() {
        this.fetch();
      },
    },
  },

  async created() {
    this.clearFilterPrevRouter();
    this.fetchDebounce = debounce(this.fetch, 100);
  },

  methods: {
    init() {
      this.$on('refresh', this.fetchDebounce);
    },
    ...mapActions('esocial/esocialMain', [
      'changeFilterRubricas',
      'fetchDebounce',
      'fetch',
      'cleanPersistentFilter',
      'clearProp',
      'setPagination',
    ]),

    refresh(filter) {
      this.changeFilterRubricas({ prop: 'status', value: filter });
    },

    openNewRubricaModal() {
      this.$refs.modalRubricaNova.$emit('open');
    },

    async downloadXml(status) {
      let response = await eSocialService.rubricahistorico.download(status);
      let blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Report.xlsx';
      link.click();
    },

    clearFilterPrevRouter() {
      if (this.routerToFrom.to.substring('0', '5') !== this.routerToFrom.from.substring('0', '5')) {
        this.cleanPersistentFilter();
        this.clearProp('');
      } else {
      }
    },
  },
};
</script>

<style>
#subtitle {
  padding-top: 8px !important;
  font-size: 16px !important;
  color: #575767 !important;
  font-weight: regular !important;
  text-align: left !important;
}
.h2 {
  font-size: 32px;
}
#cardDash {
  border: 1px solid #e7e7fa !important;
}
</style>
