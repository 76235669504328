var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("Header", {
        attrs: {
          title: _vm.txt.pageTitle,
          subtitle: _vm.txt.pageSubtitle,
          itemsBreadCrumb: _vm.itemsBreadCrumb,
          hasAddButton: false,
          hasConfigButton: true,
          hasUpdateInfo: false,
        },
        on: { clickConfig: _vm.redirectTo },
      }),
      _c("div", [_c("TabsRubrica")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }